import { ESCAPE } from '@angular/cdk/keycodes';
import { Directive, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { IApplicationDialog } from '../application-dialog.interface';

@Directive()
export class BaseDialog<T> implements IApplicationDialog<T>, OnDestroy {
    protected destroy$: Subject<void> = new Subject<void>();

    constructor(public dialogRef: MatDialogRef<IApplicationDialog<T>>) {
        this._subscribeOnCloseByEsc(this.dialogRef);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public close(result?: T): void {
        this.dialogRef.close(result);
    }

    public submit(data: T): void {
        this.dialogRef.close(data);
    }

    private _subscribeOnCloseByEsc(dialogRef: MatDialogRef<IApplicationDialog<T>>): void {
        dialogRef
            .keydownEvents()
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: KeyboardEvent) => {
                if (event.keyCode === ESCAPE) {
                    this.close();
                    event.stopPropagation();
                }
            });
    }
}
