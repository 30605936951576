<div class="confirm-popup">
    <header class="dialog-header">
        <h2 mat-dialog-title>{{ context?.header }}</h2>
    </header>
    <mat-dialog-content>
        <div class="confirm-popup--msg">
            <p [innerHTML]="context?.title"></p>
            <div class="confirm-popup--body" *ngIf="context?.content">
                <ng-container *ngTemplateOutlet="context.content"></ng-container>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="confirm-actions-footer">
        <button mat-stroked-button id="confirm-dialog-false" [mat-dialog-close]="false" class="btn highlight">
            {{ 'common.button_no' | translate }}
        </button>
        <button mat-flat-button color="primary" id="confirm-dialog-true" [mat-dialog-close]="true" cdkFocusInitial class="btn primary">
            {{ 'common.button_yes' | translate }}
        </button>
    </mat-dialog-actions>
</div>
