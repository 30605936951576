import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppSettings } from '../settings';
import { IPermissions } from '../auth/models';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    public permissions: IPermissions;

    constructor(private _http: HttpClient) {}

    public getUserPermissions(): Observable<IPermissions> {
        return this._http
            .get<IPermissions>(`${AppSettings.apiHost}/user/v2/user/permissions`)
            .pipe(tap((permissions: IPermissions) => (this.permissions = permissions)));
    }
}
