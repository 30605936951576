import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

export type SameSite = 'Lax' | 'None' | 'Strict';

export interface CookieOptions {
    domain?: string;
    expires?: string | number | Date;
    path?: string;
    sameSite?: SameSite;
    secure?: boolean;
}

@Injectable()
export class CookieService {
    constructor(@Inject(DOCUMENT) private _document: Document) {}

    public set(key: string, value: string, cookieOptions?: CookieOptions): void {
        const options = {
            path: '/',
            ...cookieOptions,
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

        Object.entries(cookieOptions).forEach(([optionKey, optionValue]: [string, unknown]) => {
            cookieString += `; ${optionKey}`;

            if (optionValue !== true) {
                cookieString += `=${optionValue}`;
            }
        });

        this._document.cookie = cookieString;
    }

    public get(key: string): string {
        if (!this.check(key)) {
            return '';
        }

        const regExp: RegExp = this._getCookieRegExp(key);
        const result: RegExpExecArray = regExp.exec(this._document.cookie);

        return result[1] ? this._safeDecodeURIComponent(result[1]) : '';
    }

    public check(key: string): boolean {
        const regExp: RegExp = this._getCookieRegExp(key);

        return regExp.test(this._document.cookie);
    }

    public remove(key: string, options?: CookieOptions): void {
        const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
        this.set(key, '', { expires: expiresDate, ...options });
    }

    private _getCookieRegExp(key: string): RegExp {
        const escapedKey: string = encodeURIComponent(key).replace(/([[\]{}()|=;+?,.*^$])/gi, '\\$1');

        return new RegExp(`(?:^${escapedKey}|;\\s*${escapedKey})=(.*?)(?:;|$)`, 'g');
    }

    private _safeDecodeURIComponent(encodedURIComponent: string): string {
        try {
            return decodeURIComponent(encodedURIComponent);
        } catch {
            return encodedURIComponent;
        }
    }
}
