export enum ResponseCode {
    NoResponse = 0,
    OK = 200,
    NotFound = 404,
    Unauthorized = 401,
    BadRequest = 400,
    InternalServerError = 500,
    BadGateway = 502,
    ServiceUnavailable = 503,
    NoContent = 204,
}
