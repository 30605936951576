import { NgModule } from '@angular/core';
import { MaterialModule } from './material';
import { DialogsModule, ExpansionMenuModule } from './pecan';
import { PhonePipe } from './pecan/pipes/phone.pipe';
import { PopupOnHoverComponent } from './pecan/popups/popup-on-hover/popup-on-hover.component';

const MODULES = [MaterialModule, ExpansionMenuModule, DialogsModule];

const COMPONENTS = [PopupOnHoverComponent];

const PIPES = [PhonePipe];

const DIRECTIVES = [];

@NgModule({
    declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    exports: [...MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
    imports: [...MODULES],
})
export class LayoutModule {}
