import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarCustomComponent } from '../dialogs';
import { SNACK_BAR_CONFIG_DEFAULT_SUCCESS, SNACK_BAR_CONFIG_ERROR } from '../constants';
import { SnackBarType } from '../dialogs/enums';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
    constructor(
        private _translate: TranslateService,
        private _snackBar: MatSnackBar
    ) {}

    public showDefaultSuccessNotification(text?: string): MatSnackBarRef<SnackBarCustomComponent> {
        return this._snackBar.openFromComponent(SnackBarCustomComponent, {
            ...SNACK_BAR_CONFIG_DEFAULT_SUCCESS,
            data: {
                message: this._translate.instant(text ?? 'changes_saved_successfully'),
                type: SnackBarType.Success,
            },
        });
    }

    public showDefaultErrorNotification(text: string): MatSnackBarRef<SnackBarCustomComponent> {
        return this._snackBar.openFromComponent(SnackBarCustomComponent, {
            ...SNACK_BAR_CONFIG_ERROR,
            data: {
                message: this._translate.instant(text),
                type: SnackBarType.Error,
            },
        });
    }
}
