import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SnackBarCustomComponent } from './snack-bar-custom/snack-bar-custom.component';

const COMPONENTS = [AlertDialogComponent, ConfirmDialogComponent, SnackBarCustomComponent];

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [CommonModule, MaterialModule, TranslateModule],
})
export class DialogsModule {}
