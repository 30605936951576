import { NgModule } from '@angular/core';
import { ExpansionMenuComponent } from './expansion-menu.component';

const COMPONENTS = [ExpansionMenuComponent];

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [],
})
export class ExpansionMenuModule {}
