import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUserExtended } from '../../+user';
import { FormState } from './form-state';
import { scrollToElement } from '../helpers';

@Directive()
export abstract class AbstractForm<T, V> implements OnInit, OnDestroy, OnChanges {
    @Output() public readonly submitForm: EventEmitter<void> = new EventEmitter<void>();

    @Input() public entity: T;

    public form: FormGroup;

    public submitted: boolean = false;

    public state: FormState<T, V>;

    public destroy$: Subject<void> = new Subject<void>();

    constructor(private _cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.initForm();
    }

    public ngOnChanges({ entity }: SimpleChanges): void {
        if (entity?.currentValue) {
            if (this.form) {
                this.initFormValue();
            }
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public submit(): void {
        this.submitted = true;
        this.form.markAllAsTouched();

        if (this.form.valid) {
            this.submitForm.emit();
        } else {
            this._cdr.detectChanges();
            scrollToElement('.mdc-text-field--invalid');
        }
    }

    public initFormValue(): void {
        this.state.setFormStates(this.entity);
        this.form.setValue(this.state.formStateInit, { emitEvent: false });
    }

    public ignoreCharsForControl(control: AbstractControl): void {
        control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
            const filteredValue = value.replace(/[^0-9]/g, '');
            control.setValue(filteredValue, { emitEvent: false });
        });
    }

    protected initForm(): void {
        this.form = this.createForm();
        this.initFormValue();
        this.listenFormStateChange();
    }

    protected listenFormStateChange(): void {
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: IUserExtended) => {
            this.state.setFormState(this.form.getRawValue());
        });
    }

    protected abstract createForm(): FormGroup;
}
