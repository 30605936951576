import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../interfaces';

@Injectable()
export class CanDeactivateGuardService {
    public canDeactivate(component: CanComponentDeactivate): boolean | Observable<boolean> | Promise<boolean> {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
