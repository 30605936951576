import { Directive, HostListener, Injector, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SnackBarService } from '../../layout';
import { CurrentUserService } from '../auth';
import { AbstractForm } from './abstract-form';
import { FormManager } from './form.manager';

@Directive()
export abstract class FormContainer<T, V> implements OnDestroy {
    public readonly formComponent: AbstractForm<T, V>;

    public manager: FormManager;

    public isResultSaved: boolean = false;

    protected currentUserService: CurrentUserService;

    protected snackBarService: SnackBarService;

    protected destroy$: Subject<void> = new Subject<void>();

    public get isEntityNotChanged(): boolean {
        return this.formComponent?.state.checkEntityEquality();
    }

    constructor(injector: Injector) {
        this.manager = injector.get(FormManager);
        this.currentUserService = injector.get(CurrentUserService);
        this.snackBarService = injector.get(SnackBarService);
    }

    @HostListener('window:beforeunload', ['$event'])
    public unloadHandler(): boolean {
        if (this.currentUserService.userAccount) {
            return this.formComponent.state.checkEntityEquality();
        }

        return true;
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onSave(): void {
        this.formComponent.submit();
    }

    public onSubmit(): void {
        this.manager
            .sendSaveChangesRequest(this.saveChanges(this.formComponent.state.getEntity()), this.formComponent.form)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.isResultSaved = true;
                this.snackBarService.showDefaultSuccessNotification('changes_saved_successfully');
                this.onSaveSuccess();
            });
    }

    public onCancel(): void {
        if (this.isEntityNotChanged) {
            this.dispose();

            return;
        }

        this.manager
            .openConfirmationDialog()
            .pipe(filter(Boolean))
            .subscribe(() => {
                this.dispose();
                this.formComponent.initFormValue();
            });
    }

    public canDeactivate(): Observable<boolean> | boolean {
        if (this.formComponent.state.checkEntityEquality() || this.isResultSaved) {
            return true;
        }

        return this.manager.openConfirmationDialog();
    }

    public checkObjectsEqualityWithOutProperties(withoutProperties: string[]): boolean {
        return this.formComponent.state.checkEntityEqualityWithOutProperties(withoutProperties);
    }

    protected dispose(): void {
        //should be declared
    }

    protected abstract onSaveSuccess(): void;

    protected abstract saveChanges(data: T): Observable<T>;
}
