import { MatSortable, SortDirection as MaterialSortDirection } from '@angular/material/sort';
import { SortDirection } from '../enum';

interface ISearchParameters {
    sortBy?: string;
    sortDirection?: number;
}

export function getMatSortable(searchParameters: ISearchParameters): MatSortable {
    const { sortBy, sortDirection } = searchParameters;

    if (sortBy && sortDirection) {
        return {
            disableClear: false,
            id: searchParameters?.sortBy,
            start: sortDirection === SortDirection.asc ? 'asc' : 'desc',
        };
    }
}

export function fromMatSortDirection(matSortDirection: MaterialSortDirection): SortDirection {
    switch (matSortDirection) {
        case 'asc':
            return SortDirection.asc;
        case 'desc':
            return SortDirection.desc;
        default:
            return SortDirection.desc;
    }
}
