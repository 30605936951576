<div class="snack-bar-block">
    <div class="content">
        <span
            class="ico"
            [ngClass]="{
                'icon-check-circle': data.type === snackBarType.Success,
                'icon-Info': data.type === snackBarType.Info,
                'icon-notific': data.type === snackBarType.Error,
            }"></span>
        <p>{{ data.message }}</p>
    </div>
    <button matSnackBarAction type="button" (click)="snackBarRef.dismissWithAction()" class="close-dialog-btn">
        {{ 'close_dialog' | translate }}
    </button>
</div>
