import { ConnectedPosition } from '@angular/cdk/overlay';

export const POPUP_OVERLAY_POSITION_RIGHT: ConnectedPosition[] = [
    {
        offsetX: 20,
        offsetY: 4,
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
    },
    {
        offsetX: 20,
        offsetY: 0,
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
    },
];

export const POPUP_OVERLAY_POSITION_LEFT: ConnectedPosition[] = [
    {
        offsetX: -4,
        offsetY: 20,
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
    },
    {
        offsetX: -4,
        offsetY: -20,
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
    },
];
